.b-maduracion {
  // border: rgba($color: #000000, $alpha: 0.1) solid 10px;
  // padding: 2rem;
  // border-radius: 10px;
  // margin-bottom: 2rem;
  border: 1px solid #e1e1e1;
  padding: 2rem;
  margin-bottom: 1.875rem;
  border-radius: 0.25rem;
  box-shadow: $box-shadow;

  &__tipo {
    font-weight: bold;
    color: $color-gris-dark;
    margin-bottom: 2rem;
  }
  &__producto {
    color: $color-gris-dark;
    font-weight: bold;
    font-size: 1.2rem;
    display: inline-block;
    margin-bottom: 1rem;
    text-decoration: none;
  }
}

.p-maduracion {
  padding: 1rem;
}
