.fincas {
  background-color: $color-gris-lighter;

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__item {
    margin: 2rem 0;
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    gap: 2rem;
    background-color: $color-blanco;
    // border: 1px solid $color-gris-06;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: $box-shadow;
    @media screen and (max-width: 991px) {
      margin: 1rem 0;
    }
    @media screen and (max-width: 991px) {
      display: flex;
      flex-direction: column;
    }
  }

  &__header {
    grid-column: 1 / span 2;
  }
  &__titulos {
  }

  &__col1 {
    @media screen and (max-width: 575px) {
      // grid-column: 1 / span 2;
    }
    .titulos__mvl {
      padding: 2rem 2rem 0 2rem;
      margin-bottom: 2rem;
      @media screen and (max-width: 575px) {
        padding: 1rem 1rem 0 1rem;
        margin-bottom: 1rem;
      }
    }
  }

  &__col2 {
    align-self: center;
    padding: 2rem;
    @media screen and (max-width: 991px) {
      width: 100%;
    }
    @media screen and (max-width: 575px) {
      //grid-column: 1 / span 2;
      padding: 1rem;
    }
  }

  &__col3 {
    grid-column: 1 / span 2;
  }

  &__detalle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 3rem;
    @media screen and (max-width: 991px) {
      margin-bottom: 2rem;
      gap: 1rem;
    }
  }

  &__resumen {
  }

  &__cultivo {
    display: flex;
    gap: 1rem;
  }

  &__variedades {
    display: flex;
    flex-wrap: wrap;
  }

  &__variedad {
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    font-size: 0.875rem;
    border: 1px solid $color-principal;
    background-color: $color-principal;
    color: $color-blanco;
    padding: 0.3rem 0.7rem;
    border-radius: 0.25rem;
    box-shadow: $box-shadow;
    a {
      color: $color-blanco;
      text-decoration: none;
    }
    @media screen and (max-width: 575px) {
      padding: 0.2rem 0.5rem;
      font-size: 0.8rem;
    }
  }

  &__img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    @media screen and (max-width: 991px) {
      height: auto;
    }
  }
  &__icon {
    width: 9%;
    fill: $color-principal;
    margin-right: 1rem;
    @media screen and (max-width: 575px) {
      width: 14%;
    }
  }
  &__superficie {
    font-weight: bold;
    font-size: $font-size * 1.5;
    margin-top: 0rem;
    margin-bottom: 3px;

    // @media screen and (max-width: 575px) {
    //   font-size: $font-size;
    // }
  }
  &__ubicacion {
    color: $color-principal;
    font-size: 0.875rem;
    margin-bottom: 0;
  }

  &__maduraciones {
    margin-top: 3rem;
    padding: 2rem;
    border: 1px solid #e1e1e1;
    border-radius: 0.25rem;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  }
  &__maduracion {
    padding: 1rem 0;
    .b-maduracion {
      border: none;
      box-shadow: none;
      margin-bottom: 0;
    }
  }
}

.fincas__item:nth-child(1) {
  margin-top: 0;
}

.fincas__item:nth-last-child(1) {
  margin-bottom: 0;
}

.fincas__item:nth-child(even) {
  grid-template-columns: 1fr 1.5fr;
  @media screen and (max-width: 991px) {
    display: flex;
    flex-direction: column;
  }

  .fincas__col1 {
    order: 2;
    @media screen and (max-width: 991px) {
      order: 1;
    }
  }

  .fincas__col2 {
    order: 1;
    padding-left: 4rem;
    @media screen and (max-width: 991px) {
      order: 2;
      width: 100%;
      padding-left: 2rem;
    }
    @media screen and (max-width: 575px) {
      padding-left: 1rem;
    }
  }
}

.page-template-page-finca .fincas {
  background-color: $color-background;

  .fincas__item {
    box-shadow: none;
    border-radius: 0;
  }
  .fincas__img {
    height: auto;
  }
}

// .page-template-page-finca .fincas__item:nth-child(even) .fincas__col1 {
//   order: 1;
// }
// .page-template-page-finca .fincas__item:nth-child(even) .fincas__col2 {
//   order: 2;
// }

//// Page-Fincas ////

.p-fincas {
  &__container {
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 575px) {
      grid-template-columns: 1fr;
    }
  }

  &__titulo {
    margin-bottom: 2rem;
  }

  &__item {
    position: relative;
  }

  &__overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    z-index: 1;
    transition: $trans;
    background-color: rgba($color-negro, $alpha: 0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 991px) {
      opacity: 1;
      background-color: rgba($color-negro, $alpha: 0.5);
    }
  }
  &__info {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: $color-blanco;
    a {
      color: $color-blanco;
      text-decoration: none;

      &:hover {
        color: rgba($color-blanco, $alpha: 0.6);
      }
    }
  }

  &__img {
    position: relative;
    width: 100%;
    height: auto;
    object-fit: cover;
    opacity: 1;
    transition: $trans;
  }

  &__titulo {
    margin-bottom: 0.25rem;
  }

  &__superficie {
    font-size: 1.1rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 1rem;
  }
  &__ubicacion {
    font-size: 1rem;
    color: $color-principal;
  }
}

.p-fincas__item:hover .p-fincas__overlay {
  opacity: 1;
  transition: $trans;
}
