.empresa {
  background-color: $color-gris-lighter;

  &__container {
    display: grid;
    grid-template-columns: 1.5fr 2fr;
    gap: 3rem;

    @media screen and (max-width: 575px) {
      grid-template-columns: 1fr;
      row-gap: 20px;
    }
  }

  &__col1 {
    img {
      width: 100%;
      height: auto;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    @media screen and (max-width: 575px) {
      grid-row: 2;
    }
  }

  @media screen and (max-width: 575px) {
    padding-bottom: 4rem 0;
  }
}

.plantones {
  &__container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;

    @media screen and (max-width: 575px) {
      grid-template-columns: 1fr;
    }
  }

  &__detalle {
    display: grid;
    grid-template-columns: 0.2fr 1fr;
    column-gap: 1rem;
    margin-bottom: 1rem;
  }

  &__icon {
    width: 90%;
    fill: $color-principal;
    grid-row: 1 / span 2;

    @media screen and (max-width: 575px) {
      width: 80%;
    }
  }

  &__nombre {
    grid-column: 2;

    p {
      margin-bottom: 0px;
    }
  }

  &__title {
    font-weight: bold;
    font-size: $font-size * 1.5;
    margin-top: 0rem;
    margin-bottom: 3px;

    @media screen and (max-width: 575px) {
      font-size: $font-size;
    }
  }

  &__subtitle {
    color: $color-principal;
    font-size: 90%;
  }

  &__img {
    width: 50%;
    display: block;
    margin: 2.5rem auto 0rem auto;

    @media screen and (max-width: 575px) {
      width: 30%;
    }
  }

  &__descripcion {
    color: $color-gris;
  }
}

.background {
  background-image: url(https://va.cucotime.com/wp-content/themes/va/assets/site/vivero.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-color: $color-principal-light;
  position: relative;
  @media screen and (max-width: 575px) {
    background-size: auto;
  }

  &__overlay {
    position: absolute;
    clip-path: polygon(0% 0%, 50% 0%, 60% 100%, 0% 100%);
    background-color: rgba($color: $color-principal, $alpha: 0.8);
    width: 100%;
    top: 0;
    bottom: 0;

    @media screen and (max-width: 991px) {
      clip-path: polygon(0% 0%, 80% 0%, 90% 100%, 0% 100%);
    }

    @media screen and (max-width: 575px) {
      clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    }
  }

  &__container {
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    color: $color-background;

    @media screen and (max-width: 575px) {
      height: 400px;
      justify-content: center;
      padding-bottom: 0;
    }
  }

  &__title {
    font-size: $font-size * 2.3;
    font-weight: bold;
    margin-bottom: 1rem;
    color: $color-secundari;

    @media screen and (max-width: 575px) {
      font-size: $font-size * 2;
      margin-bottom: 1rem;
    }
  }

  &__text {
    width: 40%;
    //margin-bottom: 1.5rem;

    @media screen and (max-width: 991px) {
      width: 76%;
    }
    @media screen and (max-width: 575px) {
      width: 100%;
      margin-bottom: 0;
    }
  }

  &__pretitle {
    font-size: $font-size * 0.9;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 0rem;
  }

  &__buttons {
    a {
      margin-right: 0.1rem;
    }
  }
}

.video {
  background: url(https://va.cucotime.com/wp-content/uploads/2021/06/VIVEROS-ALCANAR-kumquat.jpg);
  background-position: center center;
  background-size: cover;
  position: relative;

  &__container {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 640px) {
      height: 230px;
    }
  }

  &__titulo {
    color: $color-background;
    font-size: $font-size * 2;
    z-index: 2;
    position: relative;

    span {
      color: $color-secundari;
    }

    &:hover {
      text-decoration: none;
      color: darken($color: $color-background, $amount: 13);

      span {
        color: darken($color: $color-secundari, $amount: 8);
      }
    }

    @media screen and (max-width: 575px) {
      font-size: $font-size * 2;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    img {
      width: 30px;
      height: 30px;
    }
  }

  &__overlay {
    position: absolute;
    background-color: rgba($color: $color-principal, $alpha: 0.9);
    width: 100%;
    top: 0;
    bottom: 0;
  }

  &__icon {
    fill: $color-background;
    width: 4rem;
    margin: 0px 1rem;

    @media screen and (max-width: 575px) {
      width: 3rem;
      margin: 0rem 0.5rem;
    }
  }
}

.novedades {
  //margin: 3rem 0;
  &__content {
    .owl-stage {
      display: flex;
    }
  }

  &__item {
    background-color: $color-blanco;
    border: 1px solid $color-gris-06;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: $box-shadow;
    //padding: 3rem;
    display: flex;
    flex: 1 0 auto;
    height: 100%;
  }

  &__noticia {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem 3rem;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1.5fr 2fr;
      gap: 1.5rem;
    }
    @media screen and (max-width: 575px) {
      display: flex;
      flex-direction: column;
    }
  }

  &__left {
    align-self: center;
  }

  &__titulo {
    grid-column: 1 / span 2;
    margin-bottom: 0.5rem;
  }

  &__right {
    align-self: center;
    padding: 3rem 3rem 3rem 0;
    a {
      text-decoration: none;
    }
    @media screen and (max-width: 991px) {
      padding: 1.5rem 1.5rem 1.5rem 0;
    }
    @media screen and (max-width: 575px) {
      padding: 0 1.5rem 1.5rem 1.5rem;
    }
    .titulos {
      @media screen and (max-width: 991px) {
        margin-bottom: 1rem;
      }
    }
  }

  &__img {
    width: 100%;
    height: auto;
  }

  &__descripcion {
    color: $color-gris;

    @media screen and (max-width: 991px) {
      text-align: left;
    }
  }

  &__contacto {
    text-align: center;
    align-self: center;
  }
}

.bio {
  background-color: $color-gris-lighter;

  &__container {
  }

  &__content {
  }

  &__lista {
    grid-column: 1 / span 2;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.2rem;
    @media screen and (max-width: 575px) {
      grid-template-columns: 1fr;
      gap: 0.5rem;
    }
  }

  &__col1 {
  }

  &__col2 {
  }

  &__item {
    display: flex;
    flex-direction: column;
    //border: 1px solid #e1e1e1;
    //background-color: $color-background;
    padding: 1.2rem;
    //margin-bottom: 1.875rem;
    //border-radius: 0.25rem;
    //box-shadow: $box-shadow;
    text-align: center;
    @media screen and (max-width: 991px) {
      padding: 1rem;
    }
  }

  &__icono {
    width: 38%;
    height: auto;
    padding: 0 2rem 2rem 2rem;
    @media screen and (max-width: 991px) {
      padding: 0 1rem 1rem 1rem;
      width: 50%;
    }
    @media screen and (max-width: 575px) {
      width: 35%;
    }
  }

  &__subtitle {
    color: $color-principal;
    text-transform: uppercase;
    font-size: 1.1rem;
    font-weight: bold;
    @media screen and (max-width: 991px) {
      font-size: 1rem;
    }
  }
  &__descripcion {
    color: $color-gris;
    font-size: $font-size-small;
    @media screen and (max-width: 991px) {
    }
  }
}

a.bio__item {
  text-decoration: none;
}

.sub {
  padding: $sp-1 0;
  background: rgb(240, 240, 240);

  &__container {
    display: flex;
    justify-content: center;
    img {
      border-radius: 1rem;
      margin-left: auto;
      margin-right: auto;
      max-width: 750px;
      height: auto;
      box-shadow: $box-shadow;
    }
  }
}

.psub {
  padding: $sp-1 0;
  background: darkslategray;
  &__content {
    display: flex;
    justify-content: center;
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    max-width: 960px;
  }

  &__item {
    img {
      width: 100%;
      height: auto;
      border-radius: $font-size;
      box-shadow: $box-shadow;
    }
  }
}
