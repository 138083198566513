.bioseguridad {
  &__content {
  }

  &__contenido {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 6rem;
    margin-top: 2rem;
    margin-bottom: 3rem;
    @media screen and (max-width: 575px) {
      grid-template-columns: 1fr;
      column-gap: 2rem;
      row-gap: 0rem;
    }
  }

  &__titulo {
    line-height: 1;
    margin-bottom: 1.2rem;
  }

  &__descripcion {
  }

  &__medidas {
    margin-top: 1rem;
    margin-bottom: 3rem;
  }

  &__medida {
  }
}

.galeria {
  padding-bottom: 2px;
  &__content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2px;
    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width: 575px) {
      grid-template-columns: 1fr;
    }
  }

  &__item {
  }

  &__img {
    width: 100%;
    height: auto;
  }
}
