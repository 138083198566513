.titulos {
  &__desktop {
    @media screen and (max-width: 991px) {
      display: none;
    }
  }
  &__mvl {
    @media screen and (min-width: 992px) {
      display: none;
    }
    @media screen and (max-width: 991px) {
      margin-bottom: 0;
    }
  }
  margin-bottom: $sp-1;

  &__head,
  &__doscolores {
    font-weight: bold;
    font-size: $font-size * 2;
    margin-top: 0;

    span {
      color: $color-principal;
    }
    @media screen and (max-width: 991px) {
      font-size: $font-size * 1.8;
    }

    @media screen and (max-width: 575px) {
      font-size: $font-size * 1.6;
    }
  }

  &__header,
  &__header--01 {
    font-weight: bold;
    font-size: $font-size * 2;
    margin-top: 0;
    span {
      color: $color-principal;
    }
    @media screen and (max-width: 991px) {
      font-size: $font-size * 1.8;
    }
    @media screen and (max-width: 575px) {
      font-size: $font-size * 1.6;
    }
  }

  &__subheader,
  &__header--02 {
    font-weight: bold;
    font-size: $font-size * 2;
    margin-top: 0;
    span {
      color: $color-principal;
    }
    @media screen and (max-width: 991px) {
      font-size: $font-size * 1.8;
    }
    @media screen and (max-width: 575px) {
      font-size: $font-size * 1.6;
    }
  }

  &__header--03 {
    font-weight: bold;
    font-size: $font-size * 1.5;
    margin-bottom: 1.25rem;
  }

  &__header--04 {
    font-weight: bold;
    font-size: $font-size * 1.1;
    margin-top: 0;
  }

  &__min {
    font-weight: bold;
    font-size: 1.5rem;
    margin-top: 0rem;
    margin-bottom: 3px;
    @media screen and (max-width: 575px) {
      font-size: 1.3rem;
    }
  }

  &__subtitulo {
    margin-top: 0.5rem;
    font-size: $font-size;
    color: $color-gris;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: $sp-2;

    @media screen and (max-width: 575px) {
      margin-top: 1.5rem;
      font-size: 90%;
      margin-bottom: $sp-1;
    }
  }

  &__subtitulo--padb {
    margin-top: 0.5rem;
    font-size: $font-size;
    color: $color-gris;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  @media screen and (max-width: 575px) {
    margin-bottom: $sp-1 * 0.5;
  }

  &__line {
    &::before {
      content: "";
      display: block;
      background-color: $color-principal;
      margin: 0 0 0.5rem 0;
      width: 3.6rem;
      height: 0.7rem;
      border-radius: 50px;
    }
  }

  &--sub {
    margin-bottom: 0;
  }

  &--sub-02 {
    margin-bottom: 0.25rem;
  }
  &--sub-03 {
    margin-bottom: 2rem;
  }

  &--up {
    text-transform: uppercase;
  }

  &--light {
    color: $color-blanco;
    &.titulos__line::before {
      background-color: $color-blanco;
    }
  }

  &--green {
    color: $color-principal;
  }
}

a.titulos__header--01,
a.titulos__header--02,
a.titulos__header--03,
a.titulos__header--04 {
  color: $color-gris-dark;
  text-decoration: none;
}

a.titulos__header--01.titulos--light,
a.titulos__header--02.titulos--light,
a.titulos__header--03.titulos--light,
a.titulos__header--04.titulos--light {
  color: $color-blanco;
  text-decoration: none;
}

a {
  .titulos__header--01,
  .titulos__header--02,
  .titulos__header--03,
  .titulos__header--04 {
    color: $color-gris-dark;
    text-decoration: none;
  }
}

a {
  .titulos__header--01.titulos--light,
  .titulos__header--02.titulos--light,
  .titulos__header--03.titulos--light,
  .titulos__header--04.titulos--light {
    color: $color-blanco;
    text-decoration: none;
  }
}

.titulos--padb {
  margin-bottom: 1rem;
}
.titulos--center {
  text-align: center;

  @media screen and (max-width: 575px) {
    text-align: end;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $typo-secundaria;
}

.negrita {
  font-weight: 700;
}
