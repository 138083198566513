.g-ornamentales {
  &__cuadricula {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 575px) {
      grid-template-columns: 1fr;
    }
  }
  &__item {
    text-align: center;
    background-color: $color-background;
    overflow: hidden;
    border: 1px solid #e1e1e1;
    margin-bottom: 30px;
    position: relative;
    border-radius: 0.25rem;
    box-shadow: $box-shadow;
  }
  &__foto {
    width: 100%;
    height: auto;
  }
  &__titulos {
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 0.3125rem;
  }
  &__desc {
    font-size: $font-size-small;
    text-align: center;
  }
}
