.button {
  display: inline-block;
  padding: 13px 30px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  border-radius: 5px;
  transition: $trans;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &:disabled {
    background-color: lighten($color: $color-principal-light, $amount: 10);

    &:hover {
      background-color: lighten($color: $color-principal-light, $amount: 10);
    }
  }

  @media screen and (max-width: 575px) {
    font-size: 0.8rem;
  }
}

.button--sm {
  padding: 8px 25px;
}

.button--green {
  background-color: $color-principal;
  color: $color-background;
  border: 1px solid $color-principal;

  &:hover {
    background-color: darken($color: $color-principal, $amount: 5);
    color: $color-background;
  }
  &:disabled {
    background-color: $color-principal-light;
    border-color: $color-principal-light;
    &:hover {
      background-color: $color-principal-light;
      border-color: $color-principal-light;
    }
  }
}

.button--white {
  padding: 12px 29px;
  border: 2px solid $color-background;
  background: transparent;
  color: $color-background;

  &:hover {
    background: $color-background;
    color: $color-principal;
  }
}
