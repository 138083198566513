.tipos {
  &__container {
  }

  &__content {
    // display: flex;
    // flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }

  &__item {
  }

  &__titulo {
    a {
      text-decoration: none;
    }
  }
}
