.g-empresa {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;
  }

  &__video {
    iframe {
      width: 100%;
    }
  }
  &__descripciones {
  }
  &__origenes {
  }
  &__viveristas {
  }

  &__titulo-min {
    font-weight: bold;
    font-size: $font-size * 1.5;
    margin-top: 0rem;
    margin-bottom: 20px;
  }
}

.certificaciones {
  padding: 4rem 0;
  background-color: $color-principal;
  margin-bottom: 2px;

  &__container {
  }

  &__content {
  }

  &__title {
    font-size: 2.3rem;
    font-weight: bold;
    margin-bottom: 3rem;
    color: $color-blanco;
  }

  &__lista {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 6rem;

    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
      gap: 5rem;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  &__logo {
    margin-bottom: 2rem;
    img {
      height: 128px;
      width: auto;
    }
    @media screen and (max-width: 991px) {
      text-align: center;
    }
  }

  &__text {
    color: $color-blanco;
  }
}

.programa-prod {
  display: grid;
  grid-template-columns: 1.5fr 4fr;
  gap: 4rem;
  @media screen and (max-width: 575px) {
    grid-template-columns: 1fr;
    row-gap: 0.5rem;
  }
}

.g-instalaciones {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;
  @media screen and (max-width: 575px) {
    grid-template-columns: 1fr;
    row-gap: 0.5rem;
  }
  &__item {
  }
  &__foto {
    width: 100%;
    height: auto;
    margin-bottom: 1rem;
  }
  &__titulo {
  }
}

.galeria-empresa {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2px;
  row-gap: 2px;
  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 575px) {
    grid-template-columns: 1fr;
  }
  &__item {
    img {
      width: 100%;
      height: auto;
    }
  }
}
