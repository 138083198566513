.navbar-brand {
}

.nav-link {
}

.header .nav-link {
  font-size: 0.85rem;
}

#navbarSupportedContent {
  justify-content: flex-end;
}
