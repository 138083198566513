.contacto {
  &__content {
    display: grid;
    gap: 3rem;
    grid-template-columns: 1fr 1.5fr;

    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
      gap: 2rem;
    }

    @media screen and (max-width: 575px) {
      grid-template-columns: 1fr;
    }
  }

  &__datos {
    margin: 1rem auto;
    padding-left: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    li {
      display: grid;
      grid-template-columns: 1fr 3fr;
      gap: 0.5rem;
      margin-bottom: 8px;

      span {
        font-weight: bold;
        width: 35%;
        display: block;
      }
    }
  }
  &__form-priv {
    font-size: 0.7rem;
  }
}

.page__entry {
  margin-top: 10px;
}

.mapa {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;
    row-gap: 4rem;
  }

  iframe {
    height: 340px;
  }

  &__datos {
    margin-top: 1rem;
  }
  &__titulo {
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  &__numeros {
    ul {
      list-style: none;
      padding-left: 0px;
    }
    li {
      span {
        font-weight: bold;
        display: inline-block;
        width: 120px;
      }
    }
  }
  &__mail {
    span {
      font-weight: bold;
      display: inline-block;
      width: 120px;
    }
  }
  &__direccion {
    span {
      font-weight: bold;
      display: inline-block;
      width: 120px;
    }
  }
}
