.hero {
  background: url("https://va.cucotime.com/wp-content/themes/va/assets/site/bg-patrones.jpg");
  background-size: cover;
  background-color: $color-principal-light;
  position: relative;
  overflow: hidden;
  &__video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1;
    object-fit: contain;
  }

  &__container {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    height: 100vh;
    padding-bottom: 90px;

    @media screen and (min-width: 992px) and (min-height: 800px) {
      height: 800px;
    }
  }

  &__buttons {
    margin: 1.875rem 0px;
    @media screen and (max-width: 575px) {
      margin: 1.25rem 0px;
      .button--green {
        margin-bottom: 1rem;
      }
    }
  }

  &__title {
    font-size: $font-size * 3;
    font-weight: bold;
    margin-top: 0rem;
    color: $color-background;

    @media screen and (max-width: 575px) {
      font-size: $font-size * 2.8;
    }
  }

  &__subtitle {
    font-size: $font-size * 2;
    margin-bottom: 0rem;
    color: $color-background;

    @media screen and (max-width: 575px) {
      font-size: $font-size * 1.5;
    }
  }
}
