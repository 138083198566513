.preheader {
  padding: 0.3rem 0;
  height: 2.4rem;
  background-color: $color-principal;
  position: fixed;
  width: 100%;
  z-index: 4;
  transition: $trans-3;
  opacity: 1;
  @media screen and (max-width: 575px) {
    height: 2.9rem;
  }
  &__container {
    height: 100%;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    margin: auto 0;
  }

  &__info {
    display: flex;
    gap: 2rem;
    color: $color-background;
    font-weight: lighter;
    font-size: 0.8125rem;
    @media screen and (max-width: 575px) {
      flex-direction: column;
    }
    & a {
      color: $color-background;
      text-decoration: none;
      &:hover {
        color: $color-background;
      }
    }
  }
  &__info-icono {
    //color: $color-principal;
  }

  &__email {
    @media screen and (max-width: 991px) {
      margin-left: 1rem;
    }
    @media screen and (max-width: 575px) {
      display: none;
    }
  }

  &__extra {
    display: flex;
    align-items: center;
  }

  &__social {
    display: flex;
    align-items: center;
    @media screen and (max-width: 575px) {
      display: none;
    }
    & .social-icon {
      color: $color-background;
      &:hover {
        color: $color-background;
      }
    }
    & .zoom-social_icons-list__item {
      margin-bottom: 0 !important;
      margin-top: 6px !important;
    }
  }
  &--scroll {
    transition: $trans-3;
    opacity: 0;
    z-index: 1;
  }
  &__lang {
    margin-left: 1rem;
    padding: 0.1rem 0 0.1rem 10px;
    @media screen and (max-width: 575px) {
      margin-left: 0;
      padding-left: 0;
    }

    .wpml-ls-legacy-dropdown {
      width: auto;
    }
    .wpml-ls-legacy-dropdown a {
      color: $color-background;
      border: none;
      background-color: transparent;
      padding: 0;
    }
    .wpml-ls-legacy-dropdown a:hover,
    .wpml-ls-legacy-dropdown a:focus,
    .wpml-ls-legacy-dropdown .wpml-ls-current-language:hover > a {
      color: #000;
      background: transparent;
    }
    .wpml-ls-legacy-dropdown a.wpml-ls-item-toggle {
      padding: 0rem;
    }
    .wpml-ls-legacy-dropdown a.wpml-ls-item-toggle:after {
      display: none;
    }
    .wpml-ls-legacy-dropdown .wpml-ls-flag {
      width: auto;
      margin: 0.4rem;
    }

    .wpml-ls-legacy-list-horizontal {
      padding-right: 0;
      a {
        padding: 5px;
      }
    }

    .wpml-ls {
      .wpml-ls-current-language .wpml-ls-flag {
        box-shadow: 0px 0px 0px 1px rgba($color-blanco, $alpha: 0.8);
      }
      .wpml-ls-flag {
        margin-bottom: 0.1rem;
        display: inline;
        vertical-align: middle;
        width: 23px;
        height: 21px;
        border: 1px solid rgba($color-blanco, $alpha: 0.5);
        border-radius: 16px;
        &:hover {
          box-shadow: 0px 0px 0px 1px rgba($color-blanco, $alpha: 0.8);
        }
      }
    }
  }
}

.header {
  background-color: rgba($color: #000000, $alpha: 0.4);
  color: $color-background;
  position: fixed;
  top: 2.4rem;
  width: 100%;
  z-index: 3;
  padding: 0.4rem 0;
  transition: $trans;

  @media screen and (max-width: 575px) {
  }

  &__container {
    //max-width: 1360px;
  }

  &__content {
    display: flex;
    justify-content: space-between;
  }

  &__logo {
    max-width: 140px;
    height: auto;
  }

  &__burger {
    margin-right: 1rem;
    a {
      color: $color-background;
      i {
        font-size: 2rem;
      }
      &:hover {
        color: $color-gris-02;
      }
    }
    @media screen and (min-width: 1200px) {
      display: none;
    }
    @media screen and (max-width: 575px) {
      font-size: 1.5rem;
    }
  }

  &__navbar {
    width: 100%;
    ul {
      width: 100%;
      list-style: none;
      padding-left: 0;
      padding-right: 10px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 0;
    }
    a {
      color: $color-background;
      // text-transform: uppercase;
      text-decoration: none;
      font-size: 0.95rem;

      &:hover {
        color: $color-principal;
      }
      &:focus {
        color: darken($color-principal, 10%);
      }
    }
  }

  &__menu {
  }

  &--scroll {
    padding: 5px 0px;
    transition: $trans;
    background-color: rgba($color: #000000, $alpha: 0.5);
    top: 0;

    @media screen and (max-width: 575px) {
      padding: 3px 0px;
    }
  }
}

.header__menu-container{
  @media screen and (max-width: 1199px) {
    display: none;
  }
}

.header__menu {
  align-items: center;
  gap: 1rem;
}

.ubermenu-nav {
  @media screen and (max-width: 992px) {
    display: none;
  }
}

.header__submenu {
  width: 100%;
  height: 100vh;
  background-color: $color-blanco;
  position: fixed;
  top: 0;
  overflow-y: scroll;
  ul {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    li {
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
      @media screen and (max-width: 991px) {
        font-size: 1rem;
      }
      & > a {
        letter-spacing: 5px;
        text-decoration: none;
        color: $color-principal;
        padding: 0.4rem 0.8rem;
        display: inline-block;
        line-height: 1;
        @media screen and (max-width: 991px) {
          display: flex;
          font-size: 1.6rem;
        }
        @media screen and (max-width: 575px) {
          font-size: 1.2rem;
        }
        &:hover {
          color: rgba($color-principal, $alpha: 0.7);
        }
      }
    }
  }

  // &-lang {
  //   ul {
  //     display: block;
  //   }
  //   .wpml-ls-current-language .wpml-ls-flag {
  //     outline: 1px solid rgba($color-principal, $alpha: 0.8);
  //   }
  //   .wpml-ls-flag {
  //     margin-bottom: 0.1rem;
  //     display: inline;
  //     vertical-align: middle;
  //     width: 23px;
  //     height: 21px;
  //     border: 1px solid rgba($color-principal, $alpha: 0.5);
  //     border-radius: 16px;
  //     &:hover {
  //       outline: 1px solid rgba($color-principal, $alpha: 0.8);
  //     }
  //   }
  // }

  &.oculto {
    display: none;
    transition: $trans;
  }
  &.activo {
    display: flex;
    flex-direction: column;
    transition: $trans;
    z-index: 99;
  }
  &--navbar {
    display: flex;
    flex-direction: column;
    margin-top: 1.4rem;
  }
  &--cerrar {
    z-index: 2;
    position: relative;
    font-size: 2rem;
    line-height: 1;
    padding-right: 1rem;
    padding-top: 1rem;
    color: $color-principal;
    text-align: right;
    a {
      color: $color-principal;
      &:hover {
        color: rgba($color-principal, $alpha: 0.7);
      }
    }
    @media screen and (max-width: 575px) {
      font-size: 1.8rem;
    }
  }
  &--logo {
    margin-bottom: 2rem;
    max-height: 4.5rem;
    width: auto;
  }
}
