.page-title {
  background-image: url(https://va.cucotime.com/wp-content/themes/va/assets/site/vivero-1.jpg);
  background-size: cover;
  //background-color: $color-principal-light;
  position: relative;
  padding-top: 260px;
  padding-bottom: 60px;
  @media screen and (min-width: 768px) {
    padding-top: 350px;
  }

  &__overlay {
    position: absolute;
    background-color: $color-overlay;
    width: 100%;
    top: 0;
    bottom: 0;
  }

  &__text {
    position: relative;
    color: $color-principal;
  }

  &__title {
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 0rem;
    font-size: 3.5rem;
    margin-bottom: 0.5rem;
    @media screen and (max-width: 575px) {
      font-size: 2.8rem;
    }
  }

  &__breadcrumbs {
    color: $color-background;
    font-size: 0.8rem;

    a {
      color: $color-principal;
      font-weight: bold;
      text-decoration: none;
    }
  }
}

.post-type-archive-ornamentales .page-title {
  background-image: url(../../../../../wp-content/uploads/2021/06/VIVEROS-ALCANAR-kumquat.jpg);
  background-position: center top;
}

.page-template-page-empresa .page-title {
  background-image: url(../../../../../wp-content/uploads/2021/06/VIVEROS-ALCANAR-empresa-1.jpg);
  background-position: center center;
}

.page-template-page-fincas .page-title {
  background-image: url(../../../../../wp-content/uploads/2021/07/marti-viveros-alcanar.jpeg);
  background-position: center center;
}

.page-template-page-bioseguridad .page-title {
  background-image: url(../../../../../wp-content/uploads/2021/06/VIVEROS-ALCANAR-empresa-bioseguridad.jpg);
  background-position: center center;
}

.page-template-index .page-title {
  background-image: url(../../../../../wp-content/uploads/2021/07/marti-viveros-alcanar.jpeg);
  background-position: center center;
}

.page-template-page-video .page-title {
  background-image: url(../../../../../wp-content/uploads/2021/06/VIVEROS-ALCANAR-empresa-bioseguridad.jpg);
  background-position: center center;
}

.post-type-archive-productos .page-title {
  background-image: url(../../../../../wp-content/uploads/2021/06/VIVEROS-ALCANAR-productos.jpg);
  background-position: center;
}

.single-productos .page-title {
  background-image: url(../../../../../wp-content/uploads/2021/06/VIVEROS-ALCANAR-productos.jpg);
  background-position: center;
}

.tax-tipo .page-title {
  background-image: url(../../../../../wp-content/uploads/2021/06/VIVEROS-ALCANAR-productos.jpg);
  background-position: center;
}

.page-template-page-maduracion .page-title {
  background-image: url(../../../../../wp-content/uploads/2021/06/viveros-alcanar-maduracion.jpg);
  background-position: center top;
}

.page-template-page-contacto .page-title {
  background-image: url(../../../../../wp-content/uploads/2021/06/viveros-alcanar-contacto.jpg);
  background-position: center;
}

.post-type-archive-patrones .page-title {
  background-image: url(../../../../../wp-content/uploads/2021/06/VIVEROS-ALCANAR-patrones.jpg);
  background-position: center top;
}

.single-patrones .page-title {
  background-image: url(../../../../../wp-content/uploads/2021/06/VIVEROS-ALCANAR-patrones.jpg);
  background-position: center top;
}
