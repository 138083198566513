$color-principal: #87ba45;
$color-principal-dark: darken($color-principal, 30%);
$color-principal-light: lighten($color-principal, 30%);
$color-secundari: #bf0;

$color-alternativo: #bf0;

$color-gris-lighter: rgba(0, 0, 0, 0.05);
$color-gris-light: rgba(0, 0, 0, 0.15);
$color-gris: rgba(0, 0, 0, 0.5);
$color-gris-dark: rgba(0, 0, 0, 0.8);

$color-gris-01: #242328;
$color-gris-02: #1a191e;
$color-gris-03: #595959;
$color-gris-04: #888888;
$color-gris-05: #717173;

$color-gris-06: #e1e1e1;

$color-overlay: rgba(0, 0, 0, 0.5);

$color-background: rgba(255, 255, 255, 1);
$color-blanco: rgba(255, 255, 255, 1);
$color-negro: rgba(0, 0, 0, 1);

$font-size: 1rem;
$font-size-small: 0.8rem;

$font-weight: 400;

$box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.07);

$border-radius: $font-size / 2;

$trans: 0.2s all ease-in-out;
$trans-3: 0.3s all ease-in-out;
$trans-4: 1s all ease-in-out;

$sp-1: 3rem;
$sp-2: 7rem;
$sp-3: 12rem;

$typo-principal: "Open Sans", sans-serif;
$typo-secundaria: "Montserrat", sans-serif;
