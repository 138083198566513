.owl-theme .owl-dots .owl-dot span {
  background: $color-principal-light !important;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: $color-principal !important;
}

.owl-nav button {
  font-size: 2rem !important;
  color: $color-principal !important;
}

.owl-nav .owl-prev {
  margin-right: 3rem !important;
}

.owl-nav .owl-next {
  margin-left: 3rem !important;
}
