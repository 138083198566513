.entrada {
  &__content {
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    gap: 3rem;
    @media screen and (max-width: 991px) {
      display: flex;
      gap: 0;
      flex-direction: column;
    }
  }

  &__left {
    align-self: center;
    @media screen and (max-width: 991px) {
      margin-bottom: 2rem;
    }
  }
  &__right {
    align-self: center;
  }

  &__img {
    width: 100%;
    height: auto;
    border-radius: 0.5rem;
  }

  &__titulo {
    color: $color-principal;
    font-weight: bold;
    margin-top: 0rem;
    margin-bottom: 0.3rem;
    text-transform: uppercase;
    font-size: 90%;
  }
}
