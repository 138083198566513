.blog {
  &__titulo {
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__item {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
    margin-bottom: 3rem;
    border: 1px solid $color-gris-06;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: $box-shadow;
  }

  &__thumb {
    align-self: center;
  }

  &__img {
    width: 100%;
    height: auto;
  }

  &__info {
    align-self: center;
    padding-right: 3rem;
    a {
      text-decoration: none;
      color: $color-gris-dark;
    }
  }

  &__descripcion {
  }
}

.blog__item:nth-last-child(1) {
  margin-bottom: 0;
}
