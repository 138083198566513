.g-patrones {
  display: grid;
  grid-template-columns: 1.5fr 3fr;
  gap: 2rem;
  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;
  }
  &__imagen {
    @media screen and (max-width: 575px) {
      order: 1;
    }
    img {
      width: 100%;
      height: auto;
    }
  }

  &__listado {
    @media screen and (max-width: 991px) {
      order: 2;
    }
  }
  &__lista {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    font-size: 0.875rem;
    border: 1px solid $color-principal;
    background-color: $color-principal;
    color: $color-blanco;
    padding: 0.3rem 0.7rem;
    border-radius: 0.25rem;
    box-shadow: $box-shadow;
    a {
      color: $color-blanco;
      text-decoration: none;
    }
    @media screen and (max-width: 575px) {
      padding: 0.2rem 0.5rem;
      font-size: 0.8rem;
    }
  }

  &__titulo {
    font-weight: bold;
    font-size: $font-size * 3;
    margin-top: 0rem;
    margin-bottom: 2rem;
    color: $color-principal-light;
  }

  &__productos {
  }
}

.g-patrones__item:nth-child(even) {
  text-align: right;
  @media screen and (max-width: 991px) {
    text-align: center;
  }
}

.g-patron {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 3rem;
  &__foto {
    border: 1px solid #e1e1e1;
    border-radius: 0.25rem;
    box-shadow: $box-shadow;

    img {
      width: 100%;
      height: auto;
    }
  }

  &__info {
  }

  &__follaje {
    img {
      width: 100%;
      height: auto;
    }
  }
}
