.a-logo {
  padding: 7rem 0 5rem 0;
  @media screen and (max-width: 991px) {
    padding: 5rem 0 3rem 0;
  }
  @media screen and (max-width: 767px) {
    padding: 4rem 0 2.5rem 0;
  }



  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__logo {
    padding: 1.5rem 2.5rem;
    background-color: $color-principal;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      width: 200px;
      height: auto;
      @media screen and (max-width: 575px) {
        width: 160px;
      }
    }
  }
}


.maquinaria {
  padding: 5rem 0;
  @media screen and (max-width: 991px) {
    padding: 3rem 0;
  }
  @media screen and (max-width: 767px) {
    padding: 2.5rem 0;
  }

  &__content {}

  &__descripcion {
    padding: 1rem 0;
    border-top: 1px solid $color-principal;
    border-bottom: 1px solid $color-principal;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.875rem;
    color: $color-principal;
    p:last-child{
      margin-bottom: 0;
    }
    @media screen and (max-width: 991px) {
      font-size: 1.25rem;
    }
    @media screen and (max-width: 575px) {
      font-size: 1.125rem;
    }
  }
}

.proyecto {
  padding: 5rem 0;
  @media screen and (max-width: 991px) {
    padding: 3rem 0;
  }
  @media screen and (max-width: 767px) {
    padding: 2.5rem 0;
  }

  &__content {
    
  }

  &__descripcion {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
    color: $color-negro;
    p:last-child{
      margin-bottom: 0;
    }
    @media screen and (max-width: 991px) {
      font-size: 2rem;
    }
    @media screen and (max-width: 575px) {
      font-size: 1.5rem;
    }
  }
}

.a-logos {
  padding: 5rem 0 7rem 0;
  @media screen and (max-width: 991px) {
    padding: 3rem 0 5rem 0;
  }
  @media screen and (max-width: 767px) {
    padding: 2.5rem 0 3rem 0;
  }

  &__content {
  }

  &__items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5rem;
    @media screen and (max-width: 991px) {
      gap: 2.5rem;
      flex-wrap: wrap;
      justify-content: center;
    }
    @media screen and (max-width: 767px) {
      gap: 1.5rem 2.5rem;
    }
  }

  &__item {
    img{
      width: 100%;
      height: auto;
      max-width: 260px;
      max-height: 60px;
      @media screen and (max-width: 991px) {
        max-width: 180px;
        max-height: 30px;
      }
    }
  }
}
