.footer {
  background-color: $color-gris-01;
  color: $color-background;

  &__container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 30px;

    @media screen and (max-width: 575px) {
      grid-template-columns: 1fr 1fr;
      column-gap: 10px;
    }
  }

  &__link {
    text-decoration: none;
    color: $color-principal;

    &:hover {
      text-decoration: none;
      color: $color-gris;
    }
  }

  &__copy {
    grid-column: 1 / span 4;
    text-align: center;
  }

  &__enlaces {
    grid-column: 1 / span 4;
    text-align: center;

    a {
      padding: 0rem 1rem;
    }
  }

  &__col3 {
    @media screen and (max-width: 575px) {
      grid-column: 1;
      grid-row: 2;
    }
  }

  &__col4 {
    @media screen and (max-width: 575px) {
      grid-column: 2;
      grid-row: 2;
    }
  }
  &__rs {
    & .social-icon {
      color: $color-gris-03 !important;
      &:hover {
        color: $color-principal !important;
      }
    }
    ul {
      margin-left: -10px !important;
    }
  }
}

.footer__rs .social-icon-link:nth-child(1) .social-icon {
  margin-left: 0;
  padding-left: 0;
}

.widget {
  &__title {
    font-size: 0.85rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 1.5rem;
    font-weight: bold;

    @media screen and (max-width: 575px) {
      margin-bottom: 0.5rem;
      font-size: 0.8rem;
    }
  }
  .menu {
    list-style: none;
    padding-left: 0;
    &-item {
      a {
        font-size: 0.8rem;
        color: $color-gris-05 !important;
        text-decoration: none;
        &:hover {
          color: $color-principal !important;
        }
      }
    }
  }
}

.textwidget {
  p {
    margin-bottom: 0.5rem;
    font-size: $font-size-small;
    color: $color-gris-05;

    @media screen and (max-width: 575px) {
      font-size: $font-size-small * 0.95;
      margin-bottom: 0.2rem;
    }
  }

  a {
    color: $color-gris-05 !important;
    &:hover {
      color: $color-principal !important;
    }
  }

  @media screen and (max-width: 575px) {
    margin-bottom: 1.5rem;
  }
}

.copyright {
  padding: 2rem 0;
  background-color: $color-gris-02;
  color: $color-background;
  &__content {
    display: flex;
    justify-content: space-between;
  }

  &__item {
    color: $color-gris-03;
  }

  &__texto {
  }

  &__enlaces {
  }

  &__enlace {
  }

  &__desarrollador {
  }

  &__item {
  }

  &__social {
    & .social-icon {
      color: $color-gris-03 !important;
      &:hover {
        color: $color-principal !important;
      }
    }
    & .zoom-social_icons-list__item {
      margin-bottom: 0 !important;
    }
  }
}
