.g-productos {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 3rem;
  margin-bottom: 4rem;
  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr 1fr;
    row-gap: 1rem;
  }
  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;
  }
  &__imagen {
    img {
      width: 100%;
      height: auto;
    }
  }

  &__lista {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    font-size: 0.875rem;
    border: 1px solid $color-principal;
    background-color: $color-principal;
    color: $color-blanco;
    padding: 0.3rem 0.7rem;
    border-radius: 0.25rem;
    box-shadow: $box-shadow;
    a {
      color: $color-blanco;
      text-decoration: none;
    }
    @media screen and (max-width: 575px) {
      padding: 0.2rem 0.5rem;
      font-size: 0.8rem;
    }
  }

  &__titulo {
    font-weight: bold;
    font-size: $font-size * 3;
    margin-top: 0rem;
    margin-bottom: 2rem;
    color: $color-principal-light;
  }

  &__productos {
  }
}

.g-productos__item:nth-child(even) {
  //text-align: right;
}
