body {
  background-color: $color-background;
  font-size: $font-size;
  color: $color-gris-dark;
  font-weight: $font-weight;
  font-family: $typo-principal;

  @media screen and (max-width: 575px) {
    font-size: $font-size * 0.9;
  }
}

a {
  color: $color-principal;
  &:hover {
    color: darken($color-principal, 10%);
    text-decoration: none;
  }
}

.menu-visible {
  overflow-y: hidden;
}
