.producto {
  &__content {
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    gap: 3rem;
    @media screen and (max-width: 991px) {
      display: flex;
      gap: 0;
      flex-direction: column;
    }
  }

  &__left {
    @media screen and (max-width: 991px) {
      margin-bottom: 2rem;
    }
  }
  &__right {
  }

  &__img {
    width: 100%;
    height: auto;
    border-radius: 0.5rem;
  }

  &__titulo {
    color: $color-principal;
    font-weight: bold;
    margin-top: 0rem;
    margin-bottom: 0.3rem;
    text-transform: uppercase;
    font-size: 90%;

    &--mb {
      margin-bottom: 1rem;
    }
  }

  &__tabla {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem 1rem;
    margin-bottom: 3rem;
    @media screen and (max-width: 575px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__observaciones {
    &-content {
      p {
        margin-bottom: 0.5rem;
        font-size: $font-size;
      }
    }
  }

  &__detalle {
    font-size: $font-size-small;
  }
}

.productos {
  .page__entry {
    margin-top: 0.3rem;
    margin-bottom: 3rem;

    p {
      margin-bottom: 0.5rem;
      font-size: $font-size;
    }
  }
}

.cuadro {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  border: 1px solid rgba($color: #000000, $alpha: 0.1);
  padding: 1rem 2rem;
  gap: $font-size / 2;
  border-radius: $font-size;
  @media screen and (max-width: 991px) {
    grid-template-columns: repeat(6, 1fr);
  }
  @media screen and (max-width: 575px) {
    grid-template-columns: repeat(3, 1fr);
  }
  &__mes {
  }

  &__titulo {
    margin-bottom: 0.5rem;
    font-weight: bold;
    font-size: 0.7rem;
    color: $color-principal;
    text-align: center;
    text-transform: uppercase;
  }

  &__semana {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2px;
  }
  &__dia {
    height: 1.3rem;
    background-color: rgba($color: #000000, $alpha: 0.1);
    border: 1px solid rgba($color: #000000, $alpha: 0.2);
    border-radius: 2px;
  }
}

.cuadro--maduracion {
  border: 0;
  margin-bottom: 2rem;
  padding: 0;
  @media screen and (max-width: 991px) {
    margin-bottom: 3rem;
  }
  @media screen and (max-width: 575px) {
    margin-bottom: 4rem;
  }
}

.cuadro--naranja .cuadro__dia--active {
  background-color: #f39501;
  border: 1px solid #f39501;
}

.cuadro--clementina .cuadro__dia--active {
  background-color: rgb(239, 190, 99);
  border: 1px solid rgb(239, 190, 99);
}

.cuadro--hibridos .cuadro__dia--active {
  background-color: rgb(99, 239, 176);
  border: 1px solid rgb(99, 239, 176);
}

.cuadro--limoneros .cuadro__dia--active {
  background-color: rgb(231, 221, 34);
  border: 1px solid rgb(231, 221, 34);
}

.cuadro--pomelos .cuadro__dia--active {
  background-color: rgb(252, 131, 113);
  border: 1px solid rgb(252, 131, 113);
}

.cuadro__dia--active {
  background-color: #f39501;
  border: 1px solid #f39501;
}

.cuadro--productos {
  grid-template-columns: repeat(12, 1fr);
  margin-bottom: 0.5rem;
  @media screen and (max-width: 991px) {
    grid-template-columns: repeat(6, 1fr);
  }
  @media screen and (max-width: 575px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.cuadro--productos .cuadro__dia--active {
  background-color: #f39501;
  border: 1px solid #f39501;
}

.relacionados {
  &__titulo {
    font-size: 0.9rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 1rem;
    color: $color-principal-light;
  }
  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0.5rem 0rem;
    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(3, 1fr);
      gap: 0.5rem;
    }
    @media screen and (max-width: 575px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  &__producto {
    font-size: 1.2rem;
    a {
      color: $color-principal;
      text-decoration: none;
      &:hover {
        color: darken($color-principal, 10%);
      }
    }
  }
}
